import React from 'react';

import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { Box, Section } from '@nib/layout';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';

import Layout from '../components/Layout';
import MemberStory from '../components/MemberStory';
import UtilityButtons from '../components/UtilityButtons';
import { urlConstants } from '../constructs/constants';
import { UtilityLinkModel } from '../constructs/models';
import metrics from '../metrics';

export const memberStoriesPageQuery = graphql`
  query MemberStoriesPageQuery {
    allContentfulMemberStory(sort: { fields: order, order: ASC }) {
      edges {
        node {
          videoUrl
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export interface ContentfulMemberStoryNode {
  videoUrl: string;
  title: string;
  description: {
    childMarkdownRemark: {
      html: string;
    };
  };
}

const utilityLinks: UtilityLinkModel[] = [
  {
    title: 'Compare options',
    subtitle: 'Looking for a plan?',
    url: '/compare-plans',
  },
  {
    title: 'Get a quote',
    subtitle: 'How much will it cost?',
    url: urlConstants.getQuickQuote(),
  },
  {
    title: 'Find out more',
    subtitle: 'Want better health?',
    url: '/health-and-wellbeing',
  },
  {
    title: 'Contact us',
    subtitle: 'Need some help?',
    url: urlConstants.contactUs,
  },
];

const title = 'Member stories';
const description =
  'How do nib health insurance members make the most of their cover? Learn how we can help give you peace of mind.';

const MemberStoriesPage = ({ data }): JSX.Element => (
  <Layout>
    <Helmet>
      <title>{title} | nib Health Insurance</title>
      <meta name="description" content={description} />
    </Helmet>
    <Section role="section">
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <Heading component="h1" size={2} color="trueGreen">
          Member stories
        </Heading>
      </Box>
      <Copy align="center" large measure={false}>
        See how our members make the most of their health insurance.
      </Copy>
      {data.allContentfulMemberStory.edges.map((element, index) => (
        <MemberStory memberStory={element.node} videoOnRight={index % 2 === 0} key={index} />
      ))}
    </Section>
    <Section role="section" background="warmWhite60" padding={4}>
      <UtilityButtons buttons={utilityLinks} />
    </Section>
  </Layout>
);

export default metrics({ pageName: 'member-stories' })(MemberStoriesPage);
